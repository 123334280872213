import * as Sentry from '@sentry/react';
import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {BrowserRouter, Route, Routes} from 'react-router-dom';

import {setLocalStorage} from '@chancer/common/lib/core/actions/localStorage/LocalStorageActions';
import {addOverlay} from '@chancer/common/lib/core/actions/overlay/OverlayActions';
import {getNewsLastReadMs} from '@chancer/common/lib/core/selectors/localStorage/LocalStorageSelectors';
import {getNews} from '@chancer/common/lib/core/selectors/news/NewsSelectors';
import {getCurrentOverlay} from '@chancer/common/lib/core/selectors/overlay/OverlaySelectors';
import {getUsersUnopenedMessagesCount} from '@chancer/common/lib/core/selectors/user/UserSelectors';
import {LocalStorageKey} from '@chancer/common/lib/core/state/model/LocalStorage';
import {
  getNativeAppGroupOverlay,
  getNativeAppPromptOverlay,
  getUpgradePromptOverlay,
} from '@chancer/common/lib/interfaces/overlay/OverlayCreator';
import {openAccountScreen} from '../actions/webViewState/WebViewStateActions';
import {trackGroupInviteOpened} from '../analytics/FirebaseAnalytics';
import {getIsAccountScreenRequired} from '../selectors/auth/WebAuthSelectors';
import {
  getAppPromptRequirement,
  getGroupId,
  getUpgradePromptRequirement,
} from '../selectors/host/HostSelectors';
import {getIsAccountScreenOpen} from '../selectors/webViewState/WebViewStateSelectors';
import {BottomNav} from './Core/Navigation/BottomNav';
import {
  LazyAccountScreen,
  LazyAppsPage,
  LazyChallengesPage,
  LazyCompetitionPage,
  LazyContentPage,
  LazyGroupsPage,
  LazyHomePage,
  LazyLandingPage,
  LazyLeaderboardPage,
  LazyNewsPage,
  LazyVendorPage,
  LazyYourGamesPage,
} from './LazyLoader';
import {OverlayContainer} from './Overlay/OverlayContainer';
import './WebApplication.scss';

const WebApplication: React.FC = () => {
  const dispatch = useDispatch();
  const isAccountScreenRequired = useSelector(getIsAccountScreenRequired);
  const appPromptRequirement = useSelector(getAppPromptRequirement);
  const upgradePromptRequirement = useSelector(getUpgradePromptRequirement);
  const currentOverlay = useSelector(getCurrentOverlay);
  const isAccountScreenOpen = useSelector(getIsAccountScreenOpen);
  const groupId = useSelector(getGroupId);
  const unreadMessagesCount = useSelector(getUsersUnopenedMessagesCount);
  const news = useSelector(getNews);
  const newsLastReadMs = useSelector(getNewsLastReadMs);

  const [getNativeAppGroupOverlayViewed, setGetNativeAppGroupOverlayViewed] =
    useState<Boolean>(false);

  const unreadNewsCount = useMemo(() => {
    if (!news) {
      return 0;
    }

    return news.filter((n) => n.created.toMillis() > newsLastReadMs).length;
  }, [news, newsLastReadMs]);

  useEffect(() => {
    if (isAccountScreenRequired) {
      dispatch(openAccountScreen());
    }
  }, [isAccountScreenRequired, dispatch]);

  useEffect(() => {
    if (appPromptRequirement.now) {
      dispatch(addOverlay(getNativeAppPromptOverlay()));
    }
    if (appPromptRequirement.setLast) {
      dispatch(
        setLocalStorage(
          LocalStorageKey.LAST_APP_PROMPT,
          appPromptRequirement.setLast,
        ),
      );
    }
  }, [dispatch, appPromptRequirement.now, appPromptRequirement.setLast]);

  useEffect(() => {
    if (currentOverlay === null && upgradePromptRequirement.now) {
      dispatch(
        addOverlay(
          getUpgradePromptOverlay(upgradePromptRequirement.daysRemaining),
        ),
      );
    }
  }, [
    dispatch,
    upgradePromptRequirement.now,
    upgradePromptRequirement.daysRemaining,
    currentOverlay,
  ]);

  useEffect(() => {
    if (groupId !== undefined && !getNativeAppGroupOverlayViewed) {
      setGetNativeAppGroupOverlayViewed(true);
      trackGroupInviteOpened(groupId);
      dispatch(addOverlay(getNativeAppGroupOverlay(groupId)));
    }
  }, [dispatch, getNativeAppGroupOverlayViewed, groupId]);

  return (
    <div className="application__container" style={{height: '100%'}}>
      <Sentry.ErrorBoundary>
        <BrowserRouter>
          <div className="application__screens">
            <Routes>
              <Route path="/apps" element={<LazyAppsPage />} />
              <Route path="/s/:contentId" element={<LazyContentPage />} />
              <Route path="/groups/:group" element={<LazyHomePage />} />
              <Route path="/groups" element={<LazyGroupsPage />} />
              <Route path="/yourgames" element={<LazyYourGamesPage />} />
              <Route path="/news" element={<LazyNewsPage />} />
              <Route path="/challenge" element={<LazyChallengesPage />} />
              <Route
                path="/leaderboard/:leaderboardId"
                element={<LazyLeaderboardPage />}
              />
              <Route path="/:vendor/play" element={<LazyLandingPage />} />
              <Route path="/:vendor/:key" element={<LazyCompetitionPage />} />
              <Route path="/:vendor" element={<LazyVendorPage />} />
              <Route path="/" element={<LazyHomePage />} />
            </Routes>
          </div>
          {isAccountScreenOpen && <LazyAccountScreen />}
          <BottomNav {...{unreadMessagesCount, unreadNewsCount}} />
          <OverlayContainer />
        </BrowserRouter>
      </Sentry.ErrorBoundary>
    </div>
  );
};

export default WebApplication;
