import * as React from 'react';

import {
  ICompareOverlayProps,
  IEnterCompOverlayProps,
  IGetNativeAppGroupOverlayProps,
  IGetUpgradePromptOverlayProps,
  IOverlay,
  IPlayerProfileOverlayProps,
  ISimpleMessageOverlayProps,
  IStartupOverlayProps,
  IUploadImageOverlayProps,
  OverlayDisplayState,
  OverlayType,
} from '@chancer/common/lib/interfaces/overlay/OverlayInterfaces';

import {EnterCompOverlay} from './EnterComp/EnterCompOverlay';
import {GetNativeChallengeOverlay} from './GetNative/GetNativeChallengeOverlay';
import {GetNativeGroupOverlay} from './GetNative/GetNativeGroupOverlay';
import {GetNativeMissedQuestionOverlay} from './GetNative/GetNativeMissedQuestionOverlay';
import {GetNativePromptOverlay} from './GetNative/GetNativePromptOverlay';
import {CompareOverlay} from './PlayerProfile/CompareOverlay';
import {PlayerProfileOverlay} from './PlayerProfile/PlayerProfileOverlay';
import {ShareOverlay} from './Share/ShareOverlay';
import {SimpleMessageOverlay} from './SimpleMessage/SimpleMessageOverlay';
import {StartupOverlay} from './Startup/StartupOverlay';
import {GetUpgradePromptOverlay} from './Upgrade/GetUpgradePromptOverlay';
import {UploadImageOverlay} from './UploadProfileImage/UploadImageOverlay';

export const buildOverlay = (
  overlay: IOverlay | null,
  overlayState: OverlayDisplayState,
  onClose: () => void,
  onTransitionOutComplete: () => void,
): JSX.Element | undefined => {
  if (overlay) {
    switch (overlay.type) {
      case OverlayType.ENTER_COMP:
        return (
          <EnterCompOverlay
            {...(overlay as IEnterCompOverlayProps)}
            displayState={overlayState}
            onClose={onClose}
            onTransitionOutComplete={onTransitionOutComplete}
          />
        );
      case OverlayType.SIMPLE_MESSAGE:
        return (
          <SimpleMessageOverlay
            {...(overlay as ISimpleMessageOverlayProps)}
            displayState={overlayState}
            onClose={onClose}
            onTransitionOutComplete={onTransitionOutComplete}
          />
        );
      case OverlayType.SHARE:
        return (
          <ShareOverlay
            {...overlay}
            displayState={overlayState}
            onClose={onClose}
            onTransitionOutComplete={onTransitionOutComplete}
          />
        );
      case OverlayType.UPLOAD_IMAGE:
        return (
          <UploadImageOverlay
            {...(overlay as IUploadImageOverlayProps)}
            displayState={overlayState}
            onClose={onClose}
            onTransitionOutComplete={onTransitionOutComplete}
          />
        );
      case OverlayType.PLAYER_PROFILE:
        return (
          <PlayerProfileOverlay
            {...(overlay as IPlayerProfileOverlayProps)}
            displayState={overlayState}
            onClose={onClose}
            onTransitionOutComplete={onTransitionOutComplete}
          />
        );
      case OverlayType.COMPARE:
        return (
          <CompareOverlay
            {...(overlay as ICompareOverlayProps)}
            displayState={overlayState}
            onClose={onClose}
            onTransitionOutComplete={onTransitionOutComplete}
          />
        );
      case OverlayType.GET_NATIVE_APP_MISSED_QUESTION:
        return (
          <GetNativeMissedQuestionOverlay
            {...overlay}
            displayState={overlayState}
            onClose={onClose}
            onTransitionOutComplete={onTransitionOutComplete}
          />
        );
      case OverlayType.GET_NATIVE_APP_CHALLENGE:
        return (
          <GetNativeChallengeOverlay
            {...overlay}
            displayState={overlayState}
            onClose={onClose}
            onTransitionOutComplete={onTransitionOutComplete}
          />
        );
      case OverlayType.GET_NATIVE_APP_GROUP:
        return (
          <GetNativeGroupOverlay
            {...(overlay as IGetNativeAppGroupOverlayProps)}
            displayState={overlayState}
            onClose={onClose}
            onTransitionOutComplete={onTransitionOutComplete}
          />
        );
      case OverlayType.GET_NATIVE_APP_PROMPT:
        return (
          <GetNativePromptOverlay
            {...overlay}
            displayState={overlayState}
            onClose={onClose}
            onTransitionOutComplete={onTransitionOutComplete}
          />
        );
      case OverlayType.GET_UPGRADE_PROMPT:
        return (
          <GetUpgradePromptOverlay
            {...(overlay as IGetUpgradePromptOverlayProps)}
            displayState={overlayState}
            onClose={onClose}
            onTransitionOutComplete={onTransitionOutComplete}
          />
        );
      case OverlayType.STARTUP:
        return (
          <StartupOverlay
            {...(overlay as IStartupOverlayProps)}
            displayState={overlayState}
            onClose={onClose}
            onTransitionOutComplete={onTransitionOutComplete}
          />
        );
    }
  }

  return undefined;
};
