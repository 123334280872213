import {createSelector} from 'reselect';

import {TLeaderboardSummary} from '../../../interfaces/firestore/FirestoreInterfaces';
import {isAnswerCorrect} from '../../../utils/CompetitionUtils';
import {IAppState} from '../../state/AppState';
import {ICompSummaryAndEntry} from '../../state/model/CompetitionModel';
import {
  getCompetition,
  getCompetitionCounts,
} from '../competitions/CompetitionSelectors';
import {
  getUserIsFollowingAsMap,
  getUsersCurrentCompetitionEntry,
} from '../user/UserSelectors';

export const getLeaderboardConfig = (state: IAppState) =>
  state.serverApis.firestore.leaderboardConfiguration.response;

export const getLeaderboardConfigError = (state: IAppState) =>
  state.serverApis.firestore.leaderboardConfiguration.error;

export const getLeaderboardConfigIsLoading = (state: IAppState) =>
  state.serverApis.firestore.leaderboardConfiguration.isUpdating;

export const getCompetitionLeaderboardRequest = (state: IAppState) =>
  state.serverApis.firestore.leaders.request;

export const getCompetitionLeaderboard = (state: IAppState) =>
  state.serverApis.firestore.leaders.response;

export const getCompetitionLeaderboardError = (state: IAppState) =>
  state.serverApis.firestore.leaders.error;

export const getCompetitionLeaderboardIsLoading = (state: IAppState) =>
  state.serverApis.firestore.leaders.isUpdating;

export const getNumFollowingInComp = createSelector(
  getCompetitionLeaderboard,
  getUserIsFollowingAsMap,
  (leaderboard, following) => {
    let count = 0;
    leaderboard &&
      leaderboard.leaders.forEach((leader) => {
        if (following[leader.u] === true) {
          count++;
        }
      });
    return count;
  },
);

export const getUsersCompetitionResults = createSelector(
  getUsersCurrentCompetitionEntry,
  getCompetitionCounts,
  getCompetition,
  (usersEntry, compData, comp) => {
    if (!comp) {
      return [];
    }
    if (!usersEntry) {
      return Array(compData.numQuestions).fill(0);
    }
    return Object.keys(usersEntry.answers).map((questionId, index) => {
      const answer = usersEntry.answers[questionId];
      const resolved = compData.resolvedQuestions.find(
        (r) => r.id === questionId,
      );
      return resolved
        ? isAnswerCorrect(resolved.resolved, answer)
          ? 1
          : -1
        : 0;
    });
  },
);

export const getMultiGameLeaderboardsForVendor = (
  upcomingComps: ICompSummaryAndEntry[],
  resultedComps: ICompSummaryAndEntry[],
): TLeaderboardSummary[] => {
  // Re-sort resulted
  resultedComps = [...resultedComps].sort(
    (compA, compB) =>
      compA.summary.starts.toMillis() - compB.summary.starts.toMillis(),
  );
  const allComps = upcomingComps.concat(resultedComps);
  const rtn: TLeaderboardSummary[] = [];
  allComps.forEach((c) => {
    c.summary.leaderboards.forEach((l) => {
      if (rtn.find((e) => l.id === e.id) === undefined) {
        rtn.push(l);
      }
    });
  });
  return rtn.sort((l1, l2) => l2.prizes.length - l1.prizes.length);
};
