import {useCallback, useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {
  fetchLeaderboard,
  fetchLeaderboardConfig,
} from '@chancer/common/lib/core/actions/firestore/FirestoreActions';
import {
  getCompetitionLeaderboard,
  getCompetitionLeaderboardError,
  getCompetitionLeaderboardIsLoading,
  getLeaderboardConfig,
  getLeaderboardConfigError,
  getLeaderboardConfigIsLoading,
} from '@chancer/common/lib/core/selectors/leaderboard/LeaderboardSelectors';
import {TClientDocument} from '@chancer/common/lib/interfaces/firestore/FirestoreClientInterfaces';

export const useSetOnce = <T>(value: T): T => {
  const ref = useRef<T>();
  if (ref.current === undefined) {
    ref.current = value;
  }
  return ref.current;
};

export const useRefreshClientDocument = (
  id: string | null,
  document: TClientDocument | null,
  isLoading: boolean,
  hasError: boolean,
  fetch: () => void,
  focused: boolean = true,
) =>
  useEffect(() => {
    if (
      focused &&
      id !== null &&
      (!document || document?.path.indexOf(id) === -1) &&
      !isLoading &&
      !hasError
    ) {
      fetch();
    }
  }, [document, isLoading, id, fetch, hasError, focused]);

export const useRefreshLeaderboards = (
  leaderboardId: string | null,
  isMultiGame: boolean,
  focused: boolean,
) => {
  const dispatch = useDispatch();
  // const isFocused = useIsFocused();

  const fetchLeaderboardCallback = useCallback(() => {
    if (leaderboardId !== null) {
      dispatch(fetchLeaderboard(leaderboardId, isMultiGame));
    }
  }, [leaderboardId, isMultiGame, dispatch]);

  const fetchLeaderboardConfigCallback = useCallback(() => {
    if (leaderboardId !== null) {
      dispatch(fetchLeaderboardConfig(leaderboardId));
    }
  }, [leaderboardId, dispatch]);

  const leaders = useSelector(getCompetitionLeaderboard);
  const leadersError = useSelector(getCompetitionLeaderboardError);
  const isLeadersLoading = useSelector(getCompetitionLeaderboardIsLoading);
  const leaderboardConfig = useSelector(getLeaderboardConfig);
  const leaderboardConfigError = useSelector(getLeaderboardConfigError);
  const isLeaderboardConfigLoading = useSelector(getLeaderboardConfigIsLoading);

  useRefreshClientDocument(
    leaderboardId,
    leaders,
    isLeadersLoading,
    leadersError !== null,
    fetchLeaderboardCallback,
    focused,
  );

  useRefreshClientDocument(
    leaderboardId,
    leaderboardConfig,
    isLeaderboardConfigLoading,
    leaderboardConfigError !== null,
    fetchLeaderboardConfigCallback,
    focused,
  );

  return {
    leaders,
    leadersError,
    isLeadersLoading,
    leaderboardConfig,
    leaderboardConfigError,
    isLeaderboardConfigLoading,
  };
};
